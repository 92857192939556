<template>
  <div>
    <b-card id="card-youtube-filters">
      <b-row>
        <!-- audience location -->
        <b-col class="col-12 col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block">
                    <span class="search-Map-Pin4"></span> {{$t("search.audience_location")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold d-block m-0">{{text_audience_location}}</p>
              </div>
            </template>
            <b-dropdown-header :key="update_location">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  :key="change_audience_location"
                  :show_remove="index > 0"
                  :index="index"
                  :location="location"
                  @changeLocationAudienceChild="changeLocationAudience"
                  @removeAudienceLocationChild="removeAudienceLocation"
                />
              </div>
              <div v-if="Object.keys(this.audience_locations[0]).length > 0">
                <hr/>
                <b-link v-show="this.audience_locations.length < 3" @click="addAudienceLocation()" class="text-secondary">{{$t('search.addLocation')}}</b-link>
                <div v-show="this.audience_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Audience age -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light d-block mb-1">
                    <span class="search-Busy-1"></span>{{$t("search.audience_age")}}
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{audience_ages[1] === 65 && audience_ages[0] === 10 ?  $t('search.realty.any') : getAudienceAge()}}</p>
              </div>
            </template>
            <b-dropdown-header>
              <div v-if="audience_ages[1] === 65" class="">≥ {{audience_ages[0]}} {{$t('tiktok.years')}}</div>
              <div v-else-if="audience_ages[0] === 10" class="text-right">≤ {{audience_ages[1]}} {{$t('tiktok.years')}}</div>
              <div v-else class="d-flex justify-content-between">
                <div class="">{{audience_ages[0]}} {{$t('tiktok.years')}}</div>
                <div class="">{{audience_ages[1]}} {{$t('tiktok.years')}}</div>
              </div>

              <slider-range class="mt-3" :init_range="audience_ages" :interval="1" :max_value="65" :min_value="10" identifier="audience_ages" @changeSlide="changeSlideComponent" :key="audience_ages[0] + audience_ages[1]"/>
              <div class="my-1" v-if="showPercentageAgeAudience()">{{$t("search.moreOf")}} {{percentage_audience_age}}% {{$t("search.ofAudience")}}</div>
              <vue-slider
                class="mt-3"
                v-if="showPercentageAgeAudience()"
                v-model="percentage_audience_age"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}%'"
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>  

        <!-- Audience gender -->
        <b-col  class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_gender">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light mb-1 d-block">
                    <span class="search-intersex"></span> {{$t("search.audience_gender")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{audience_gender !== null ? audience_gender.text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <b-dropdown-header :key="update_gender">
              <div v-for="(gender, index) in $t('instaGen')" :key="index" ref="gender_dropdown">
                <div 
                  :class="`button-gender 
                  ${index === 0 ? 'border-radius-top' : ''} 
                  ${index === $t('instaGen').length -1 ? 'border-radius-bottom' : ''}
                  `"
                  @click="setAudienceGender(gender, index)"
                >
                  {{gender.text}}
                </div>
              </div>
              
              <div v-if="audience_gender !== null ? audience_gender.value !== null : audience_gender !== null">
                <div class="mt-2 white-space-wrap">{{$t('search.more_than')}} {{percentage_gender}}% {{$t('search.of_audience')}}</div>
                <vue-slider
                  class="mt-1"
                  v-model="percentage_gender"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :max="100"
                  :min="55"
                  :interval="5"
                  @drag-end="changeSlide"
                  :tooltip-formatter="'{value}%'"
                />
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>     

        <!-- Puntaje calidad audiencia -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light mb-1 d-block">
                    <b-icon class="search-icon icon-size-14" icon="sort-numeric-up-alt"/><span class="ml-1">{{$t("search.point_audience")}}</span>
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{quality_audience !== null ? $t(`qualityAudience`).find((item) => item.value === quality_audience).text : $t('search.realty.any')}}</p>
              </div>
            </template>
            <options-filters :vertical="true" :options="$t('qualityAudience')" @optionsFilter="filterQualityAudience" :key="update_aqs"></options-filters>
          </b-dropdown>
        </b-col>

        <!--  Submit button -->
        <b-col class="button-submit-searcher col-md-6 col-md-4 col-lg">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>
        </b-col>
    
      </b-row>
    </b-card>

    <b-row class="m-0 p-0">
      <b-col class="order-1 order-xl-0 col-12 col-lg-6 col-xl-2 p-0 d-none d-md-block">
        <div class="more-filters-button pr-2">
          <b-dropdown block no-caret class="dropdown-filters-category" variant="outline-gray-light" menu-class="left-init-side" ref="dropdown_categories">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <span class="text-left align-middle color-gray-light mb-1 w-100">
                  <b-icon icon="filter-left"/>
                  {{$t('search.categories')}} 
                  <b-badge variant="light-secondary-v2" class="light-secondary-v2" v-if="influencer_category.length > 0">+{{influencer_category.length}}</b-badge>
                  <feather-icon icon="ChevronDownIcon" class="chevron-dropdown-categories"></feather-icon>
                </span>
              </div>
            </template>
            <b-dropdown-header :key="update_dropdown_categories">
              <div class="categories-youtube-filter d-flex flex-column flex-wrap" ref="category_filters_searcher">
                <div 
                v-for="(category, index) in categories" 
                :key="index" 
                class="col-6 ml-2 mr-0 category-item-dropdown position-relative" 
                @click="addCategoryFilter(category)"
                >
                  <feather-icon icon="CheckIcon" :ref="`check_item_category_${category.id}`" class="d-none position-absolute"></feather-icon>
                  <b-icon class="icon-categories" :icon="utils.getIconCategories(category, network)"></b-icon>
                  <span :ref="`word_item_category_${category.id}`">{{category.title}}</span>
                </div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </div>
      </b-col>
       <!-- SIMULATING DISABLED BUTTON  -->
      <b-col class="order-1 order-xl-0 col-12 col-lg-6 col-xl-2 p-0 d-md-none" id="more-filters-button-tooltip">
        <div class="more-filters-button disabled-button-categories pr-2">
          <div class="d-flex flex-column justify-content-between h-100">
            <span class="text-left align-middle mb-1 w-100 p-1">
              <b-icon icon="filter-left"/>
              {{$t('search.categories')}} 
              <feather-icon icon="ChevronDownIcon" class="chevron-dropdown-categories mr-1"></feather-icon>
            </span>
          </div>
        </div>
      </b-col>
      <b-tooltip target="more-filters-button-tooltip">{{$t('profile.access_computer')}}</b-tooltip>
      <!-- ////// -->
      <b-col class="order-0 col-xl-7 col-12 p-0 px-xl-1">
        <search-by
          :key="update_search_by"
          :keyword_dad="keywords"
          :where_dad="where"
          @add_keyword_filter="addKeywordFilter"
        />
      </b-col>
      <b-col class="order-2 col-12 col-lg-6 col-xl-3 p-0 pl-lg-1 pl-xl-0">
        <b-row>
          <b-col cols="9" class="pr-0">
            <div class="more-filters-button">
              <b-button 
                variant="outline"
                @click="open_sidebar = true"
                class="d-flex justify-content-center"
              > 
                <div>
                  <b-icon icon="sliders"></b-icon>
                  <span class="ml-1">{{$t('search.more_filters')}}</span>
                </div>
                <div class="d-flex justify-content-center filters_used_number d-block ml-1" v-if="checkFiltersUsed().length > 0"> 
                  <span>{{checkFiltersUsed().length}}</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="clear-filters-button">
              <b-button v-b-tooltip.hover :title="$t('search.reset_filters')" class="d-flex justify-content-center" variant="outline" @click="clearFilters()">
                <b-icon icon="trash" class="cursor-pointer"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel"/>
    <b-sidebar 
      v-model="open_sidebar"
      backdrop
      shadow
      right
      width="700px"
      body-class="background-white"
      no-header
      footer-class="background-white footer-sidebar-filters"
      class="sidebar-filters-nets"
    >
    <feather-icon
        class="close-component"
        icon="XIcon"
        size="16"
        @click="open_sidebar = false"
    />
      <b-row class="p-2">
      <!-- Influencer filters -->

        <b-col class="col-12 col-md">
          <div>
            <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
          </div>

          <!-- Tamaño de audiencia -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('audience_size')">
                <span class="search-Users"></span><strong>{{$t("search.audienceSize")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_audience_size"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_audience_size"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_audience_size">
              <b-dropdown :text="text_followers" no-caret block variant="outline-dark" menu-class="w-100" ref="filter_followers">
                <b-dropdown-header>
                  <followers-filter @changeFollowers="changeFollowers" :key="update_followers_filter"/>
                </b-dropdown-header>
              </b-dropdown>
            </div>
          </div>

          <!-- Ubicación del influencer -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_location')">
                <span class="search-Map-Pin4"></span><strong> {{$t("search.influencerLocation")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_location"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_location"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_location">
              <b-spinner
                v-if="loading_location_influencer"
                variant="primary"
                class="spinner-ubication"
              />
              <vue-autosuggest
                :suggestions="influencer_locations"
                :limit="10"
                :input-props="input_locations"
                @input="inputInfluencerLocation"
                v-model="influencer_location"
                :get-suggestion-value="getInfluencerLocation"
                :should-render-suggestions="shouldRenderSuggestions"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>

                <template slot="before-suggestions">
                  <div class="m-1" v-if="influencer_location !== ''">
                    {{$t("search.searchingBy")}}: {{influencer_location}}
                  </div>
                  <hr v-if="influencer_location !== ''">
                </template>
                <template slot="after-suggestions">
                  <div class="m-1" v-if="no_locations_influencer">
                    {{$t("search.noResults")}}
                  </div>
                </template>
              </vue-autosuggest>
            </div>
          </div>

          <!-- Idioma del influencer -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_language">
              <b-form-select
                v-model="influencer_language"
                :options="$t('instaIdioma')"
              />
            </div>
          </div>

          <!-- Solo verificados -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('is_verified')">
                <span class="search-Check-1"></span><strong> {{$t("search.onlyveri")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_is_verified"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_is_verified"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_is_verified">
              <options-filters :options="$t('instaVerificado')" @optionsFilter="filterVerified" :key="update_is_verified"></options-filters>
            </div>
          </div>

          <!-- Tienen información de contacto -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_info_contact">
              <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
            </div>
          </div>

          <div class="py-2" v-if="role !== 'espejo'">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('prices')">
                <strong><b-icon class="search-icon" icon="cash"/>{{$t("search.priceInfluencer")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_prices"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_prices"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_prices">
              <p class="mt-1">{{getLabelPrices()}}</p>
              <slider-range class="mt-3" :init_range="prices" :interval="10000" :max_value="1000000" :min_value="0" identifier="prices" @changeSlide="changeSlideComponent" :key="prices[0] + prices[1]"/>
            </div>
          </div>
        </b-col>

        <b-col class="col-12 col-md">
          <!-- Filtros rendimiento -->
          <div>
            <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
          </div>

          <!-- Seguridad de marca -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('brand_safety')">
                <strong>{{$t('search.brand_safety')}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_brand_safety"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_brand_safety"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_brand_safety">
              <brand-safety :brand_safety="brand_safety" :key="update_brand_safety" @changeBrandSafety="changeBrandSafety"/>
            </div>
          </div>

          <!-- Engagement rate -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('engagement_rate')">
                <span class="search-Heart-Beat"></span><strong> {{$t("search.engagementRate")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_engagement_rate"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_engagement_rate"/>
                <div class="my-1" v-if="engagement_rate > 0">{{engagement_rate}} {{$t("search.engagementRate")}}</div>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_engagement_rate">
              <vue-slider
                :tooltip="'always'"
                class="mt-3"
                v-model="engagement_rate"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="40"
                :min="0"
                :interval="0.5"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Promedio de comentarios -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_comments')">
                <strong><b-icon class="search-icon" icon="chat"/>{{$t("search.averageComments")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_comments"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_comments"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_comments">
              <div class="my-1" v-if="comments > 0">{{comments}} {{$t("search.comments")}}</div>
              <vue-slider
                class="mt-3"
                :tooltip="'always'"
                v-model="comments"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100000"
                :min="0"
                :interval="100"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              >
              </vue-slider>
            </div>
          </div>

          <!-- Promedio de reacciones -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('reactions')">
                <strong><b-icon class="search-icon" icon="camera-video"/>{{$t("search.averageReactions")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_reactions"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_reactions"/>
              </span>              
              <div class="my-1" v-if="reactions > 0">{{reactions}} {{$t("search.reactions")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_reactions">
              <vue-slider
                class="mt-3"
                :tooltip="'always'"
                v-model="reactions"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100000"
                :min="0"
                :interval="100"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              >
              </vue-slider>
            </div>
          </div>

          <!-- Tasa de comenatrios -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('comments_rate')">
                <span class="search-Speach-Bubble-6"></span><strong> {{$t("search.comment_rate")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_comments_rate"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_comments_rate"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_comments_rate">
              <b-form-select
                v-model="comments_rate"
                :options="$t('instaTasaComentarios')"
                :placeholder="$t('Any')"
              />
            </div>
          </div>

          <!-- Tasa de reacciones -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('reactions_rate')">
                <strong><b-icon class="search-icon" icon="graph-up"/>{{$t("search.reactionRate")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_reactions_rate"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_reactions_rate"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_reactions_rate">
              <b-form-select
                v-model="reactions_rate"
                :options="$t('instaTasaComentarios')"
                :placeholder="$t('Any')"
              />
            </div>
          </div>

          <!-- Aumento de seguidores -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('follower_days')">
                  <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_follower_days"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_follower_days"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_follower_days">
              <b-form-select
                v-model="followers_days"
                :options="$t('days')"
              />
              <div class="my-1" v-if="followers_days > 0">{{$t("search.increase")}} {{percentage_followers_growth}}%</div>
              <vue-slider
                :tooltip="'always'"
                class="mt-3"
                v-if="followers_days"
                v-model="percentage_followers_growth"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}%'"
              />
            </div>
          </div>

          <!-- Brand mentions -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse(18)">
                <span class="search-User-Store"></span><strong> {{$t("search.yt.textMentioned")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_18"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_18"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_18">
              <b-form-checkbox v-model="mentioned_any">
                {{$t('search.yt.hasMentioned')}}
              </b-form-checkbox>
            </div>
          </div>

          <!-- Publicación más reciente -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('mentioned_any')">
                <span class="search-Calendar"></span><strong> {{$t("search.mostRecent")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_mentioned_any"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_mentioned_any"/>
              </span>              
            </b-form-group>
            <span class="my-1" v-if="most_recent > 0"> {{most_recent}} {{$t("search.days")}}</span>
            <div class="d-none mt-1" ref="collapse_filters_mentioned_any">
              <vue-slider
                :tooltip="'always'"
                class="mt-3"
                v-model="most_recent"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100"
                :min="0"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Similar a -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('similar')">
                <span class="search-Eyedropper"></span><strong> {{$t("search.similar")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_similar"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_similar"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_similar">
              <b-spinner
                v-if="loading_similars"
                variant="primary"
                label="Text Centered"
                style="position: absolute; right: 20px; top: 28px"
              />
              <vue-autosuggest
                v-model="similar"
                :suggestions="similars"
                :input-props="input_brands"
                :limit="10"
                @input="inputSimilar"
                @selected="getSimilarValue"
                :get-suggestion-value="getValueInput"
                :should-render-suggestions="shouldRenderSuggestions"
              >
                <template slot-scope="{suggestion}">
                  <chat-contact
                    :user="suggestion.item"
                    tag="li"
                    is-chat-contact
                  />
                </template>

                <template slot="before-suggestions">
                  <div class="m-1" v-if="similar !== ''">
                    {{$t("search.searchingBy")}}: {{similar}}
                  </div>
                  <hr v-if="similar !== ''">
                </template>
                <template slot="after-suggestions">
                  <div class="m-1" v-if="no_similars">
                    {{$t("search.noResults")}}
                  </div>
                </template>
              </vue-autosuggest>
            </div>
          </div>

          <!-- Recuento de videos -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('count_videos')">
                <strong><b-icon class="search-icon" icon="camera-video"/>{{$t("search.countVideos")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_count_videos"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_count_videos"/>
              </span>              
              <div class="my-1" v-if="count_videos > 0">{{count_videos}} {{$t("search.count")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_count_videos">
              
              <vue-slider
                :tooltip="'always'"
                class="mt-3"
                v-model="count_videos"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="20000"
                :min="0"
                :interval="100"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              />
            </div>
          </div>

          <!-- Average views -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('range_views')">
                <strong><b-icon class="search-icon" icon="eye"/>{{$t("search.averageViewsVideo")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_range_views"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_range_views"/>
              </span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_range_views">
              <div v-if="range_views[1] === 10000000" class="">≥ {{utils.getFormat(range_views[0])}}</div>
              <div v-else-if="range_views[0] === 10000" class="text-right">≤ {{utils.getFormat(range_views[1])}}</div>
              <div v-else class="d-flex justify-content-between">
                <div>{{utils.getFormat(range_views[0])}}</div>
                <div>{{utils.getFormat(range_views[1])}}</div>
              </div>
              <slider-range class="mt-3" :init_range="range_views" :interval="5000" :max_value="10000000" :min_value="10000" identifier="range_views" @changeSlide="changeSlideComponent" :key="range_views[0] + range_views[1]"/>
            </div>
          </div>
        </b-col>

      </b-row>
      <template #footer>
        <!-- Button submit -->
        <b-col cols="12" class="button-submit-searcher col-12 mb-3 d-flex flex-wrap">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>

          <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
            {{$t('search.reset_filters')}}
          </b-button>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormSelect, BButton, BSpinner, BSidebar, BDropdown, BDropdownHeader, BLink, BFormCheckbox, BBadge, VBTooltip, BTooltip
} from 'bootstrap-vue'
import service from '@/services/search'
import utils from '@/libs/utils'
import axios from 'axios'
import config from '@/services/config'
import { VueAutosuggest } from 'vue-autosuggest'
import VueSlider from 'vue-slider-component'
import ChatContact from '@/views/apps/chat/ChatContact.vue'
import AudienceLocationFilter from '@/views/buscador/filters/audience_locations.vue'
import FollowersFilter from '@/views/buscador/filters/followers.vue'
import buttonSubmitFilters from './buttonSubmitFilters.vue'
import sliderRange from './sliderRange.vue'
import brandSafety from './filters/brandSafety.vue'
import applyFilters from './filters/applyFilters.vue';
import SearchBy from '@/views/components/inputs/SearchBy.vue';
import OptionsFilters from '@/views/components/button-group/OptionsFilters.vue';

export default {
  components: {
    OptionsFilters,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    VueAutosuggest,
    VueSlider,
    ChatContact,
    BSpinner,
    BDropdown,
    BDropdownHeader,
    BLink,
    AudienceLocationFilter,
    FollowersFilter,
    buttonSubmitFilters,
    sliderRange,
    brandSafety,
    BFormCheckbox,
    applyFilters,
    BBadge,
    SearchBy,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    initial_filters: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      update_info_contact: false,
      update_is_verified: false,
      update_aqs: false,
      update_dropdown_categories: false,
      update_followers_filter: false,
      update_location: false,
      update_gender: false,
      open_sidebar: false,
      utils,
      loading: false,
      total_results: 0,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      influencer_category: [],
      influencer_locations: [],
      cancel_influencer_location: null,
      influencer_location: '',
      influencer_language: null,
      is_verified: null,
      has_contacts: null,
      selected_influencer_location: null,
      quality_audience: null,
      audience_gender: null,
      percentage_gender: 55,
      audience_ages: [10, 65],
      percentage_audience_age: 10,
      range_views: [10000, 10000000],
      formatter: v => `${utils.numberFormat(v)}`,
      formatter_views: v => `${utils.getFormat(v)}`,
      comments: 0,
      reactions: 0,
      comments_rate: null,
      reactions_rate: null,
      followers_days: null,
      percentage_followers_growth: 50,
      most_recent: 0,
      input_brands: {
        class: 'form-control',
        placeholder: this.$t("search.typeUser")
      },
      similar: '',
      similars: [],
      selected_similar: null,
      cancel_suggests: null,
      count_videos: 0,
      prices: [0, 1000000],
      role: 'brand',
      loading_location_influencer: false,
      no_locations_influencer: false,
      loading_similars: false,
      no_similars: false,
      categories: [],
      audience_locations: [{}],
      audience_locations_type: [],
      audience_locations_id: [],
      audience_locations_percentage: [],
      text_audience_location: this.$t('search.realty.any'),
      change_audience_location: false,
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      filters: [
        {
          filter: 'search_by_influencer',
          text: 'anywhere',
          models: [
            {name: 'keywords', value: null, default: ''},
            {name: 'where', value: null, default: 'anywhere'}
          ],
          update_component: 'update_search_by',
          value: null,
          multiple_values: false
        },
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'audience_locations', value: null, default: [{}]},
            {name: 'text_audience_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'name',
            },
            {
              type: 'string',
              name: ' > ',
            },
            {
              type: 'var',
              name: 'percentage'
            },
            {
              type: 'string',
              name: '%',
            },
          ]
        },  
        {
          filter: 'audience_ages',
          text: this.$t("search.audienceAge"),
          models: [
            {name: 'audience_ages', value: null, default: [10, 65]},
            {name: 'percentage_audience_age', value: null, default: 10},
          ],
          value: null,
        },
        {
          filter: 'audience_gender',
          text: this.$t("search.audienceGender"),
          models: [
            {name: 'audience_gender', value: null, default: null},
            {name: 'percentage_gender', value: null, default: 55}
          ],
          update_component: 'update_gender',
          value: null,
        },
        {
          filter: 'quality_audience',
          text: this.$t("search.pointAudience"),
          models: [{name: 'quality_audience', value: null, default: null}],
          update_component: 'update_aqs',
          value: null,
        },
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
        },
        {
          filter: 'influencer_category',
          text: this.$t("search.category"),
          models: [{name: 'influencer_category', value: null, default: []}],
          update_component: 'update_dropdown_categories',
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'title',
            },
          ],
          value: null,
        },
        {
          filter: 'influencer_location',
          text: this.$t("search.influencerLocation"),
          models: [
            {name: 'influencer_location', value: null, default: ''},
            {name: 'selected_influencer_location', value: null}
          ],
          value: null,
        },
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null}],
          value: null,
        },
        {
          filter: 'is_verified',
          text: this.$t("search.onlyveri"),
          models: [{name: 'is_verified', value: null, default: null}],
          update_component: 'update_is_verified',
          value: null,
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'has_contacts', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
        },
        {
          filter: 'prices',
          text: this.$t("search.priceInfluencer"),
          models: [{name: 'prices', value: null, default: [0, 1000000]},],
          value: null,
        },
        {
          filter: 'average_comments',
          text: this.$t("search.averageComments"),
          models: [{name: 'comments', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'reactions',
          text: this.$t("search.averageReactions"),
          models: [{name: 'reactions', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'comments_rate',
          text: this.$t("search.comment_rate"),
          models: [{name: 'comments_rate', value: null, default: null}],
          value: null,
        },
        {
          filter: 'reactions_rate',
          text: this.$t("search.reactionRate"),
          models: [{name: 'reactions_rate', value: null, default: null}],
          value: null,
        },
        {
          filter: 'follower_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'followers_days', value: null, default: null},
            {name: 'percentage_followers_growth', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'most_recent',
          text: this.$t("search.mostRecent"),
          models: [{name: 'most_recent', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'similar',
          text: this.$t("search.similar"),
          models: [
            {name: 'similar', value: null, default: ''},
            {name: 'selected_similar', value: null, default: null}
          ],
          value: null,
        },
        {
          filter: 'count_videos',
          text: this.$t("search.countVideos"),
          models: [{name: 'count_videos', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'range_views',
          text: this.$t("search.averageViewsVideo"),
          models: [{name: 'range_views', value: null, default: [10000, 10000000]}],
          value: null,
        },
        {
          filter: 'brand_safety',
          text: this.$t("search.brand_safety"),
          models: [{name: 'brand_safety', value: null, default: []}],
          update_component: 'update_brand_safety',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'str_translate',
              name: 'negativeContent',
            },
          ]
        },
        {
          filter: 'engagement_rate',
          text: this.$t("search.engagementRate"),
          models: [{name: 'engagement_rate', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'mentioned_any',
          text: this.$t("search.yt.mentioned"),
          models: [{name: 'mentioned_any', value: null, default: false}],
          value: null,
        }
      ],
      searched: true,
      brand_safety: [],
      update_brand_safety: false,
      engagement_rate: 0,
      mentioned_any: false,
      keywords: '',
      where: 'anywhere',
      update_search_by: false,
      network: 'youtube'
    }
  },
  computed: {
  },
  created () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.role = userData.groups[0].name
    this.getCategories()

    if (this.initial_filters && Object.keys(this.initial_filters).length > 0) {
      this.initialSearch()
    } else {
      this.getCounter()
    }

    this.searched = true
  },
  methods: {
    initialSearch() {
      const filters = {}
      let name_country = '';
      Object.keys(this.initial_filters).forEach((key) => {
        if (key !== 'st') {
          if (key === 'audience_location') {
            filters['audience_location_id'] = [this.initial_filters[key][0].id]
            filters['audience_location_type'] = [this.initial_filters[key][0].type]
            filters['audience_location_from'] = [this.initial_filters[key][0].prc]
            name_country = this.initial_filters[key][0].name
          } else if (key === 'followers_growth') {
            filters['followers_growth_period'] = parseInt(this.initial_filters[key][0].id)
            filters['followers_growth_to'] = this.initial_filters[key][0].prc
          } else if (key === 'audience_gender') {
            filters['audience_gender_id'] = parseInt(this.initial_filters[key][0].id)
            filters['audience_gender_from'] = this.initial_filters[key][0].prc
          } else if (key === 'influencer_category') {
            filters['influencer_yt_category'] = [parseInt(this.initial_filters[key][0].id.replace('2_', ''))]
          } else if (key === 'audience_age') {
            filters['audience_age_from'] = this.initial_filters[key][0].from
            filters['audience_age_p'] = this.initial_filters[key][0].prc
          } else if (key === 'followers') {
            filters['followers_from'] = this.initial_filters[key][0].from
            filters['followers_to'] = this.initial_filters[key][0].to
          } else if (key === 'sort_order') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'sort_type') {
            filters[key] = this.initial_filters[key]
          } else {
            filters[key] = this.initial_filters[key][0].id
          }
        } else {
          filters['st'] = 'yt'
        }
      })

      this.formatApplyFilters(filters, name_country);
      this.$emit('clean_selected');
      this.searched = true

      this.$emit('callYoutube', filters)
      this.getCounter(filters)
    },
    formatApplyFilters(filters, name_country) {
      Object.keys(filters).forEach(key => {
        if (key === 'aqs') {
          const filter = this.filters.find(f => f.filter === 'quality_audience')
          filter.models[0].value = filters[key]
          filter.value = filters[key]
        } else if (key === 'audience_location_id') {
          const filter = this.filters.find(f => f.filter === 'audience_locations')
          filter.models[0].value = [{id: filter[key], name: name_country, percentage: filters['audience_location_from'], type: filters['audience_location_type']}]
          filter.value = `${name_country} ≥ ${filters['audience_location_from']}`
        } else if (key === 'followers_growth_to') {
          const filter = this.filters.find(f => f.filter === 'follower_days')
          const period = parseInt(filters['followers_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } else if (key === 'audience_gender_from') {
          const gender_text = this.$t('instaGen').find(g => g.value === filters['audience_gender_id'])

          const percentage_gender = filters[key]
          const new_models = [{name: 'audience_gender', value: filters['audience_gender_id']}]
          this.changeFilter('audience_gender', new_models, `${gender_text.text}, ≥${percentage_gender}%`)
          this.changeFilter('audience_gender', [], `${gender_text.text}, ≥${percentage_gender}%`)
        } else if (key === 'influencer_yt_category') {
          if (this.categories.length === 0) {
            setTimeout(() => {
              const cat = this.categories.find((item) => item.id === filters[key][0])
              this.influencer_category.push(cat);
              const new_models = [{name: 'influencer_category', value: this.influencer_category}];
              this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
            }, 1000)
          } else {
            const cat = this.categories.find((item) => item.id === filters[key][0])
            this.influencer_category.push(cat);
            const new_models = [{name: 'influencer_category', value: this.influencer_category}];
            this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
          }
        } else if (key === 'followers_from') {
          const filter = this.filters.find(f => f.filter === 'audience_size')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters['followers_to']
          filter.value = `${utils.getFormat(filters[key])}-${utils.getFormat(filters['followers_to'])}`
        } else if (key === 'audience_age_from') {
          this.percentage_audience_age = filters['audience_age_p']
          const new_models = [{name: 'audience_ages', value: filters['audience_age_p']}]
          this.changeFilter('audience_ages', new_models, this.getAudienceAge())
        }
      })
    },
    filterQualityAudience(options) {
      this.quality_audience = options.value
    },
    filterVerified(options) {
      this.is_verified = options.value
    },
    filterHasContacts(options) {
      this.has_contacts = options.value
    },
    addKeywordFilter(new_models, keywords, where) {
      this.keywords = keywords;
      this.where = where;
      this.filters.find((item) => item.filter === 'search_by_influencer').text = this.$t(`search.${this.where}`);
      this.changeFilter('search_by_influencer', new_models, this.keywords);
      this.getCounter();
    },
    changeInfluencerCategory(category, id_deleted) {
      const new_models = [{name: 'influencer_category', value: category}];
      this.$refs[`word_item_category_${id_deleted}`][0].classList.remove('word-break');
      this.$refs[`check_item_category_${id_deleted}`][0].classList.add('d-none');
      this.changeFilter('influencer_category', new_models, null);
      this.getCounter();
    },
    addCategoryFilter(category) {
      if (this.influencer_category.find((item) => item.id === category.id) === undefined) {
        this.influencer_category.push(category);
        const new_models = [{name: 'influencer_category', value: this.influencer_category}];
        this.changeFilter('influencer_category', new_models, this.influencer_category.length !== 0 ? this.influencer_category[0].title : null);
        this.getCounter();
        this.$refs[`word_item_category_${category.id}`][0].classList.add('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.remove('d-none');
      } else {
        this.influencer_category.forEach((item,i) => { if (item.id === category.id) this.influencer_category.splice(i,1) })
        this.$refs[`word_item_category_${category.id}`][0].classList.remove('word-break');
        this.$refs[`check_item_category_${category.id}`][0].classList.add('d-none');
        this.getCounter();
      }     
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      to_deleted.models.forEach((item) => {
        this[item.name] = item.default;
        if (item.name === 'influencer_category') item.default = []
        item.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter);
      if (get_counter) this.getCounter();
      this.searched = true
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    clearFilters() {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })

      this.sendFilters();
      this.getCounter();

      this.searched = true
      this.open_sidebar = false;
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    getAudienceAge() {
      const paragraph = `, ≥ ${this.percentage_audience_age}%`;
      let first = '';
      if (this.audience_ages[1] === 65) first = `≥ ${this.audience_ages[0]}`
      else if (this.audience_ages[0] === 10)  first = `≤ ${this.audience_ages[1]}`
      else first = `${this.audience_ages[0]} - ${this.audience_ages[1]}`;
      return first + paragraph;
    },
    setAudienceGender(gender, index) {
      this.audience_gender = gender;
      this.$refs.gender_dropdown.map((item, i) => {
        if (i === index) item.classList.add('background-clicked-form');
        else item.classList.remove('background-clicked-form');
      });
    },
    getCategories() {
      service.getCategories('youtube')
        .then(response => {
          response.forEach(cat => {
            const category = {
              id: cat.id,
              title: this.$t(`filters.${cat.title}`)
            }
            this.categories.push(category)
          });
          this.categories.sort((a, b) => (a.title < b.title ? -1 : 1));
        })
        .catch(() => {
        })
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${utils.getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    getRangeViews() {
      if (this.range_views[0] !== 10000 && this.range_views[1] !== 10000000) {
        return {
          'from': this.range_views[0],
          'to': this.range_views[1]
        }
      } else if (this.range_views[0] > 10000 && this.range_views[1] === 10000000) {
        return {
          'from': this.range_views[0],
          'to': null
        }
      } else if (this.range_views[0] === 10000 && this.range_views[1] < 10000000) {
        return {
          'from': null,
          'to': this.range_views[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRangeAgesAudience() {
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) {
        return {
          'from': this.audience_ages[0],
          'to': this.audience_ages[1]
        }
      } else if (this.audience_ages[0] > 10 && this.audience_ages[1] === 65) {
        return {
          'from': this.audience_ages[0],
          'to': null
        }
      } else if (this.audience_ages[0] === 10 && this.audience_ages[1] < 65) {
        return {
          'from': null,
          'to': this.audience_ages[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getRangePrice() {
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) {
        return {
          'from': this.prices[0],
          'to': this.prices[1]
        }
      } else if (this.prices[0] > 0 && this.prices[1] === 1000000) {
        return {
          'from': this.prices[0],
          'to': null
        }
      } else if (this.prices[0] === 0 && this.prices[1] < 1000000) {
        return {
          'from': null,
          'to': this.prices[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getCounter (prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })

      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    addAudienceLocation() {
      this.audience_locations.push({})
    },
    removeAudienceLocation(location, index, force_remove = false, from_filters = false) {
      const self = this
      setTimeout(function() {
        const is_show = self.$refs.filter_audience_location.$el.querySelector('ul').classList.contains('show')
        if (!from_filters && !is_show && force_remove) self.$refs.filter_audience_location.$el.querySelector('button').click()
      }, 15)

      if (force_remove) this.audience_locations.splice(index, 1)
      else this.audience_locations[index] = {}

      if (!location) return

      if (this.audience_locations.length === 0) {
        this.deleteFilter('audience_locations')
        this.text_audience_location = this.$t('search.realty.any')
      } else {
        let counter_empty = 0
        const location_length = this.audience_locations.filter((item) => item.id).length
        this.audience_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : ''
            this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`
          } else {
            counter_empty += 1;
          }
          if (this.audience_locations.length === counter_empty) {
            this.deleteFilter('audience_locations')
            this.text_audience_location = this.$t('search.realty.any')
          }
        });
      }

      this.update_location = !this.update_location
      this.getCounter()
    },
    changeLocationAudience(data) {
      const index = data.key
      this.audience_locations[index].id = data.location.id
      this.audience_locations[index].type = data.location.type
      this.audience_locations[index].name = data.location.name
      this.audience_locations[index].percentage = data.percentage

      const location_length = this.audience_locations.filter((item) => item.id).length;

      this.audience_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
        }
      });
      const new_models = [{name: 'audience_locations', value: this.audience_locations}]
      this.changeFilter('audience_locations', new_models, this.text_audience_location)
      this.getCounter()
    },
    changeFollowers(data) {
      this.followers_from = data.from
      this.followers_to = data.to
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${utils.getFormat(this.followers_from)}-${utils.getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${utils.getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${utils.getFormat(this.followers_to)}`
      } else {
        value_model = null
        this.text_followers = this.$t('search.realty.any')
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)
      this.getCounter()
    },
    formatAudienceLocations() {
      this.audience_locations_id = []
      this.audience_locations_type = []
      this.audience_locations_percentage = []
      if (this.audience_locations.length > 0) {
        this.audience_locations.forEach(location => {
          if (location.id) {
            this.audience_locations_id.push(location.id)
            this.audience_locations_type.push(location.type)
            this.audience_locations_percentage.push(location.percentage)
          }
        })
      }
    },
    getFilters () {
      const finalFilters = {}
      const audience_ages = this.getRangeAgesAudience()
      const views = this.getRangeViews()
      const prices = this.getRangePrice()
      this.formatAudienceLocations()

      const data = {
        st: 'yt',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_yt_category: this.influencer_category.length !== 0 ? this.influencer_category.map((item) => item.id) : null,
        influencer_location_id: this.selected_influencer_location ? [this.selected_influencer_location.id] : null,
        influencer_location_type : this.selected_influencer_location ? [this.selected_influencer_location.type] : null,
        influencer_language: this.influencer_language,
        is_verified: this.is_verified,
        has_contacts: this.has_contacts,
        aqs: this.quality_audience,
        audience_location_id: this.audience_locations_id.length > 0 ? this.audience_locations_id : null,
        audience_location_type: this.audience_locations_type.length > 0 ? this.audience_locations_type : null,
        audience_location_from: this.audience_locations_percentage.length > 0 ? this.audience_locations_percentage : null,
        audience_gender_id: this.audience_gender ? this.audience_gender.value : null,
        audience_gender_from: this.audience_gender ? this.percentage_gender : null,
        audience_age_from: audience_ages.from,
        audience_age_to: audience_ages.to,
        audience_age_p: this.showPercentageAgeAudience() ? this.percentage_audience_age : null,
        yt_views_from: views.from,
        yt_views_to: views.to,
        yt_comments: this.comments > 0 ? this.comments : null,
        yt_reactions: this.reactions > 0 ? this.reactions : null,
        comments_rate: this.comments_rate,
        yt_reactions_rate: this.reactions_rate,
        followers_growth_period: this.followers_days,
        followers_growth_to: this.followers_days ? this.percentage_followers_growth : null,
        yt_recent_video_to: this.most_recent > 0 ? this.most_recent : null,
        yt_similar: this.selected_similar ? this.selected_similar.channel_id : null,
        yt_videos_from: this.count_videos > 0 ? this.count_videos : null,
        blogger_prices_from: prices.from,
        blogger_prices_to: prices.to,
        brand_safety: this.brand_safety.length > 0 ? this.brand_safety : null,
        er: this.engagement_rate > 0 ? this.engagement_rate : null,
        mentioned_any: this.mentioned_any ? this.mentioned_any : null
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }
      return finalFilters
    },
    sendFilters () {
      this.searched = true
      const finalFilters = this.getFilters()
      this.$emit('callYoutube', finalFilters)
      this.$emit('clean_selected');
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    inputInfluencerLocation (text) {
      this.influencer_locations = []
      this.no_locations_influencer = false;
      if (text !== '') {
        this.loading_location_influencer = true;
        const CancelToken = axios.CancelToken;

        if (this.cancel_influencer_location) this.cancel_influencer_location()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/ubicaciones`,
          data:{
            query: text
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_influencer_location = c;
          })
        })
          .then(result => {
            const { countries } = result.data.data
            self.datasuggest = [...countries.map(country => ({id:country.geonameid, name:country.name, type: 0}))]
            const filteredData = self.datasuggest
            this.no_locations_influencer = filteredData.length < 1;
            this.loading_location_influencer = false;
            self.influencer_locations = [{data: filteredData}]
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            } else {
              this.no_locations_influencer = true;
              this.loading_location_influencer = false;
            }
            return []
          });
      }
    },
    getInfluencerLocation (suggestion) {
      this.influencer_location = suggestion.item.name
      this.selected_influencer_location = suggestion.item
      const new_models = [{name: 'influencer_location', value: this.influencer_location}]
      this.changeFilter('influencer_location', new_models, this.influencer_location)
      this.getCounter()
    },
    changeSlide() {
      this.getCounter()
    },
    getLabelAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return this.$t("search.anyAge")
      if (this.audience_ages[0] !== 10 && this.audience_ages[1] !== 65) return `${this.$t("search.between")} ${this.audience_ages[0]} ${this.$t("search.and")} ${this.audience_ages[1]}`
      if (this.audience_ages[0] > 10) return `${this.$t("search.lessThan")} ${this.audience_ages[0]}`
      if (this.audience_ages[1] < 65) return `${this.$t("search.greaterThan")} ${this.audience_ages[1]}`

      return this.$t("search.anyAge")
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()
    },
    showPercentageAgeAudience() {
      if (this.audience_ages[0] === 10 && this.audience_ages[1] === 65) return false
      return true
    },
    inputSimilar(text) {
      this.similars = [];
      this.no_similars = false;
      if (text !== '') {
        this.loading_similars = true;
        const CancelToken = axios.CancelToken;

        if (this.cancel_suggests) this.cancel_suggests()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: text,
            networks: 'youtube',
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_suggests = c;
          })
        })
          .then(res => {
            this.loading_similars = false;
            this.no_similars = res.data.response.list.length < 1;
            this.similars = [{data: res.data.response.list}]
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            } else {
              this.loading_similars = false;
              this.no_similars = true;
            }
          });
      }
    },
    getSimilarValue(suggestion) {
      this.similar = suggestion.item.username
      this.selected_similar = suggestion.item
      const new_models = [{name: 'similar', value: this.similar}]
      this.changeFilter('similar', new_models, this.similar)
      this.getCounter()
    },
    getValueInput(suggestion) {
      return suggestion.item.username
    },
    getLabelPrices() {
      if (this.prices[0] === 0 && this.prices[1] === 1000000) return this.$t('search.anyPrice')
      if (this.prices[0] !== 0 && this.prices[1] !== 1000000) return `${this.$t("search.between")} $${utils.numberFormat(this.prices[0])} ${this.$t("search.and")} $${utils.numberFormat(this.prices[1])}`
      if (this.prices[0] > 0) return `${this.$t("search.lessThan")} $${utils.numberFormat(this.prices[0])}`
      if (this.prices[1] < 1000000) return `${this.$t("search.greaterThan")} $${utils.numberFormat(this.prices[1])}`

      return this.$t('search.anyPrice')
    },
    getLabelViews() {
      if (this.range_views[0] === 10000 && this.range_views[1] === 10000000) return this.$t("search.anyQuantity")
      if (this.range_views[0] !== 10000 && this.range_views[1] !== 10000000) return `${this.$t("search.between")} ${utils.getFormat(this.range_views[0])} ${this.$t("search.and")} ${utils.getFormat(this.range_views[1])}`
      if (this.range_views[0] > 10000) return `${this.$t("search.lessThan")} ${utils.getFormat(this.range_views[0])}`
      if (this.range_views[1] < 10000000) return `${this.$t("search.greaterThan")} ${utils.getFormat(this.range_views[1])}`

      return this.$t("search.anyQuantity")
    },
    changeBrandSafety(brand_safety) {
      this.brand_safety = brand_safety
      const value = brand_safety.length === 0 ? null : this.$t(`negativeContent.${brand_safety[0]}`)

      const new_models = [{name: 'brand_safety', value: brand_safety.length === 0 ? null : brand_safety}]
      this.changeFilter('brand_safety', new_models, brand_safety.length > 1 ? `${value} +${brand_safety.length}` : value)
      this.getCounter()
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)

      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }
      this.input_brands = {
        class: 'form-control',
        placeholder: this.$t("search.typeUser")
      }

      if (this.audience_locations.length === 1 && !this.audience_locations[0].id) {
        this.text_audience_location = this.$t('search.realty.any')
      }
    },
    influencer_language(val) {
      const new_models = [{name: 'influencer_language', value: val ? this.$t('instaIdioma').find((item) => item.value === val).value : null}]
      this.changeFilter('influencer_language', new_models, val ? this.$t('instaIdioma').find((item) => item.value === val).text : null)
      this.getCounter()
    },
    is_verified(val) {
      const new_models = [{name: 'is_verified', value: this.$t('instaVerificado').find((item) => item.value === val).value}]
      this.changeFilter('is_verified', new_models, this.$t('instaVerificado').find((item) => item.value === val).text)
      this.getCounter()
    },
    has_contacts(val) {
      const new_models = [{name: 'has_contacts', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      this.getCounter()
    },
    quality_audience(val) {
      const new_models = [{name: 'quality_audience', value: this.$t('qualityAudience').find((item) => item.value === val).value}]
      this.changeFilter('quality_audience', new_models, this.$t('qualityAudience').find((item) => item.value === val).text)
      this.getCounter()
    },
    audience_gender(val) {
      const new_models = [{name: 'audience_gender', value: val ? val.value : null}]
      if (val && val.value === null) {
        new_models.push({name: 'percentage_gender', value: 55})
        this.percentage_gender = 55;
      }
      this.changeFilter('audience_gender', new_models, val ? `${val.text}, ≥${this.percentage_gender}%` : null)
      this.getCounter()
    },
    percentage_gender(val) {
      this.changeFilter('audience_gender', [], this.audience_gender ? `${this.audience_gender.text}, ≥${val}%` : null)
    },
    prices(val) {
      const new_models = [{name: 'prices', value: val[0] === 0 && val[1] === 1000000 ? null : val}]
      this.changeFilter('prices', new_models, val[0] === 0 && val[1] === 1000000 ? null : this.getLabelPrices())
    },
    comments_rate(val) {
      const new_models = [{name: 'comments_rate', value: this.$t('instaTasaComentarios').find((item) => item.value === val).value}]
      this.changeFilter('comments_rate', new_models, this.$t('instaTasaComentarios').find((item) => item.value === val).text)
      this.getCounter()
    },
    audience_ages(val) {
      const new_models = [{name: 'audience_ages', value: val[0] !== 10 || val[1] !== 65 ? val : null}]
      this.changeFilter('audience_ages', new_models, val[0] !== 10 || val[1] !== 65 ? this.getAudienceAge() : this.$t('search.realty.any'))

      if (val[0] === 10 && val[1] === 65) {
        this.percentage_audience_age = 10;
      }
    },
    percentage_audience_age() {
      this.changeFilter('audience_ages', [], this.getAudienceAge())
    },
    comments(val) {
      const new_models = [{name: 'comments', value: val !== 0 ? val : null}]
      this.changeFilter('average_comments', new_models, val !== 0 ? utils.separatebycomma(val) : null)
    },
    reactions(val) {
      const new_models = [{name: 'reactions', value: val !== 0 ? val : null}]
      this.changeFilter('reactions', new_models, val !== 0 ? utils.separatebycomma(val) : null)
    },
    reactions_rate(val) {
      const new_models = [{name: 'reactions_rate', value: this.$t('instaTasaComentarios').find((item) => item.value === val).value}]
      this.changeFilter('reactions_rate', new_models, this.$t('instaTasaComentarios').find((item) => item.value === val).text)
      this.getCounter()
    },
    followers_days(val) {
      const new_models = [{name: 'followers_days', value: this.$t('days').find((item) => item.value === val).value}]
      this.changeFilter('follower_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ${this.percentage_followers_growth}%`)
      this.getCounter()
    },
    percentage_followers_growth(val) {
      this.changeFilter('follower_days', [], `${this.$t('days').find((item) => item.value === this.followers_days).text}, ${val}%`)
    },
    most_recent(val) { 
      const new_models = [{name: 'most_recent', value: val !== 0 ? val : null}]
      this.changeFilter('most_recent', new_models, val !== 0 ? val : null)
    },
    count_videos(val) {
      const new_models = [{name: 'count_videos', value: val !== 0 ? val : null}]
      this.changeFilter('count_videos', new_models, val !== 0 ? utils.separatebycomma(val) : null)
    },
    range_views(val) {
      const new_models = [{name: 'range_views', value: val[0] === 10000 && val[1] === 10000000 ? null : val}]
      this.changeFilter('range_views', new_models, val[0] === 10000 && val[1] === 10000000 ? null : this.getLabelViews())
    },
    engagement_rate(val) {
      const new_models = [{name: 'engagement_rate', value: val !== 0 ? val : null}]
      this.changeFilter('engagement_rate', new_models, val !== 0 ? val : null)
    },
    mentioned_any(val) {
      const new_models = [{name: 'mentioned_any', value: val ? val : null}]
      this.changeFilter('mentioned_any', new_models, this.$t('search.realty.any'))
      this.getCounter()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.value-active {
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;

  width: 100%;
  height: 100%;
  background-color: white;
  transition: all .3s;
  border: 2px solid #7367f0;
}
.value-inactive {
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: none;
  width: 100%;
  height: 100%;
  background-color: gray;
  transition: all .3s;
  border: 2px solid white;
}
  .spinner-ubication {
    position: absolute; 
    right: 20px; top: 28px
  }
  </style>
<style>
#card-youtube-filters > .card-body {
  padding: 0.7em 1.7em;
}
#card-youtube-filters {
  margin-bottom: 0;
  box-shadow: 0 15px 18px rgb(34 41 47 / 3%) !important;
}
.icon-size-14 {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 15px;
}
.categories-youtube-filter {
  width: 750px;
  font-size: 13px;
  max-height: 420px;
  overflow: hidden;
}
</style>
