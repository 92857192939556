<template>
  <div>
    <div class="d-flex align-items-center justify-content-center my-1">
      <div class="h-90 position-relative">
        <b-spinner
          v-if="show_loader && loading_location_audience"
          variant="primary"
          label="Text Centered"
          class="spinner-audience"
        />
        <vue-autosuggest
          :suggestions="audience_locations"
          :limit="10"
          :input-props="input_locations"
          :key="change_language"
          @input="inputAudienceLocation"
          v-model="audience_location"
          :get-suggestion-value="getAudienceLocation"
          :should-render-suggestions="shouldRenderSuggestions"
        > 
          <template slot-scope="{suggestion}">
            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
          </template>

          <template slot="before-suggestions">
            <div class="m-1" v-if="audience_location !== ''">
              {{$t("search.searchingBy")}}: {{audience_location}}
            </div>
          </template>
          <template slot="after-suggestions">
            <div class="m-1" v-if="no_locations_audience">
              {{$t("search.noResults")}}
            </div>
          </template>
        </vue-autosuggest>
        <feather-icon icon="XIcon" class="x-icon-autosuggest-filter" size="16" v-show="show_reset_location && !loading_location_audience && audience_location" @click="resetAudienceLocation()"></feather-icon>
      </div>
      <div>
        <div class="d-flex align-items-center justify-content-center">
          <b-icon v-if="show_remove" class="h-90 cursor-pointer ml-1" icon="trash" @click="removeLocation()"/>
        </div>
      </div>
    </div>

    <vue-slider
      :tooltip="'always'"
      class="mb-2 mt-3"
      v-if="show_slider && selected_audience_location"
      v-model="percentage_audience_location"
      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :max="99"
      :min="1"
      :interval="1"
      @drag-end="changeSlide"
      :tooltip-formatter="'{value}%'"
    >
    </vue-slider>
  </div>
</template>

<script>
import {
  BSpinner, BIcon
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import VueSlider from 'vue-slider-component'
import axios from 'axios'
import config from '@/services/config'

export default {
  name: 'AudienceLocationFilter',
  components: {
    BSpinner,
    VueAutosuggest,
    VueSlider,
    BIcon
  },
  props: {
    show_remove: {
      required: false,
      default: false
    },
    show_slider: {
      required: false,
      default: true
    },
    location: {
      required: true,
    },
    index: {
      required: true
    },
    show_reset_location: {
      type: Boolean,
      default: true
    },
    show_loader: {
      type: Boolean,
      default: true
    },
    only_countries: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading_location_audience: false,
      audience_locations: [],
      change_language: false,
      no_locations_audience: false,
      cancel_audience_location: null,
      audience_location: '',
      selected_audience_location: null,
      percentage_audience_location: 10,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
    }
  },
  created () {
    if (this.location.id) {
      this.audience_location = this.location.name
      this.selected_audience_location = this.location
    }
  },
  methods: {
    removeLocation () {
      this.$emit('removeAudienceLocationChild', this.selected_audience_location, this.index, true)
    },
    inputAudienceLocation (text) {
      this.audience_locations = []
      if (text !== '') {
        this.loading_location_audience = true;
        this.no_locations_audience = true;
        const CancelToken = axios.CancelToken;

        if (this.cancel_audience_location) this.cancel_audience_location()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/ubicaciones`,
          data:{
            query: text,
            only_country: this.only_countries ? 1 : 0
          },
          cancelToken: new CancelToken(function executor(c) {
            self.cancel_audience_location = c;
          })
        })
          .then(result => {
            let { cities, states, countries } = result.data.data
            cities = cities ? cities : []
            states = states ? states : []
            countries = countries ? countries : []
            self.datasuggest = [
              ...cities.map(city => {
                let name = city.name
                name = `${city.name}, ${city.country.name}`
                return {id:city.geonameid, name, type: 1 }
              }),
              ...states.map(state => {
                let name = state.name
                name = `${state.name}, ${state.country.name}`
                return {id:state.geonameid, name, type: 2 }
              }),
              ...countries.map(country => ({id:country.geonameid, name:country.name, type: 0}))
            ]
            const filteredData = self.datasuggest
            this.no_locations_audience = filteredData.length < 1;
            this.loading_location_audience = false;
            self.audience_locations = [{data: filteredData}]
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            } else {
              this.loading_location_audience = false;
              this.no_locations_audience = true;
            }
            return []
          });
      }
    },
    resetAudienceLocation() {
      this.$emit('removeAudienceLocationChild', this.selected_audience_location, this.index)
      this.audience_location = ''
      this.selected_audience_location = null
      this.no_locations_audience = false;
    },
    getAudienceLocation (suggestion) {
      this.audience_location = suggestion.item.name
      this.selected_audience_location = suggestion.item
      this.$emit('changeLocationAudienceChild', {location: this.selected_audience_location, percentage: this.percentage_audience_location, key: this.index})
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    changeSlide() {
      this.$emit('changeLocationAudienceChild', {location: this.selected_audience_location, percentage: this.percentage_audience_location, key: this.index})
    },
  },
  watch: {
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }
    },
  }
}
</script>

<style>
  .h-90 {
    flex-grow: 1;
  }
</style>