<template>
  <b-row class="row-main-searcher">
    <b-col
      md="12"
      class="p-0"
    >
      <b-row>
        <b-col
          md="12"
        >
          <b-tabs v-model="active_tab" class="tabs-searcher-networks" :active-nav-item-class="`color-searcher-tab-${networkTabColor}`">
            <b-tab
              v-on:click="searchData.st='ig', getFilter(true)"
              @click="netSelected = 'instagram'"
            >
            <template #title>
              <feather-icon icon="InstagramIcon" size="20"/>
              Instagram
            </template>
            </b-tab>
            <b-tab
              v-on:click="searchData.st='yt', getFilter(true)"
              title="Youtube"
              @click="netSelected = 'youtube'"
            >
            <template #title>
              <feather-icon icon="YoutubeIcon" size="20"/>
              Youtube
            </template>            
            </b-tab>          
            <b-tab
              v-on:click="searchData.st='tt', getFilter(true)"
              @click="netSelected = 'tiktok'"
            >
            <template #title>
              <span :class="`icomoon-tiktok  tiktok-searcher-icon`" v-if="active_tab === 2" style="color: #ff0653"></span>
              <span :class="`icomoon-tiktok  tiktok-searcher-icon`" v-else></span>              
                TikTok
              </template>            
            </b-tab>
            <b-tab
              v-on:click="searchData.st='tr', getFilter(true)"
              @click="netSelected = 'twitter'"
            >
            <template #title>
              <b-img v-if="active_tab === 3" src="@/assets/images/icons/twitter-x-black.svg" class="network-x-search"/> 
              <b-img v-else src="@/assets/images/icons/twitter-x-gray.svg" class="network-x-search"/>
              Twitter
            </template>            
            </b-tab>
            <b-tab
              v-on:click="searchData.st='tw', getFilter(true)"
              @click="netSelected = 'twitch'"
            >
            <template #title>
              <feather-icon icon="TwitchIcon" size="20"/>
              Twitch
            </template>
            </b-tab>
          </b-tabs>

        </b-col>

          <filtroInstagram
            v-if="toogleFilter.st === 'ig'"
            :initial_filters="initial_filters"
            @callInstagram="searchFilters"
            ref="instagramFilter"
            class="col-12"
            @clean_selected="checked_influencer = []"
          ></filtroInstagram>

          <filtroYoutube
            v-if="toogleFilter.st === 'yt'"
            :initial_filters="initial_filters"
            @callYoutube="searchFilters"
            ref="youtubeFilter"
            class="col-12"
            @clean_selected="checked_influencer = []"
          ></filtroYoutube>

          <filtroTiktok
            v-if="toogleFilter.st === 'tt'"
            :initial_filters="initial_filters"
            @callTiktok="searchFilters"
            ref="tiktokFilter"
            class="col-12"
            @clean_selected="checked_influencer = []"
          ></filtroTiktok>
          <filtroTwitter
            v-if="toogleFilter.st === 'tr'"
            :initial_filters="initial_filters"
            @callTwitter="searchFilters"
            ref="twitterFilter"
            class="col-12"
            @clean_selected="checked_influencer = []"
          ></filtroTwitter>

          <filtroTwitch
            v-if="toogleFilter.st === 'tw'"
            :initial_filters="initial_filters"
            @callTwitch="searchFilters"
            ref="twitchFilter"
            class="col-12"
            @clean_selected="checked_influencer = []"
          ></filtroTwitch>
        <b-col
          md="10"
        >
          <b-form-input
            hidden="true"
            v-model="searchString"
            type="text"
            debounce="500"
          />
        </b-col>
        <b-col
          md="2"
        >
          <b-button
            hidden="true"
            variant="primary"
            style="width: 100%"
            v-on:click="getSearch()"
          >
            {{$t("search.buttonSearch")}} 
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <b-col
      cols="12"
      class="pl-0 pr-0"
    >
      <b-card class="table-responsive" id="table-searcher-card">
        <b-col class="d-flex responsive-mode-views flex-wrap">
          <div class="container-mode-views">
            <b-button-group>
              <b-button variant="flat-secondary" class="button-change-view flat-secondary" @click="change_view_card = true"> <b-icon icon="list-task"></b-icon> {{$t('search.list_view')}}</b-button>
              <b-button variant="flat-secondary" class="button-change-view flat-secondary" @click="change_view_card = false"> <b-icon icon="grid3x3-gap"></b-icon> {{$t('search.mosaic')}}</b-button>
            </b-button-group>
            <div class="color-gray-button responsive-button" v-if="checked_influencer.length !== 0">
              <span class="ml-2">{{$t('search.selected_influencers')}}: {{checked_influencer.length}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
            <span class="margin-right-05 color-gray-button mb-1 mb-sm-0">{{$t('search.add_to')}}:</span>
            <dropdown-campaigns 
              :campaigns="options" 
              :disabled="checked_influencer.length === 0" 
              :influencers="getFormatInfluencerToAdd()" 
              :multiple_added="true"
              class="mr-1 my-1"
            />
            <dropdown-lists
              :lists="lists"
              :disabled="checked_influencer.length === 0" 
              :influencers="getFormatInfluencerToAdd()"
              :multiple_added="true"
              class="my-1"
            />
          </div>
        </b-col>         
      </b-card>
      <div class="position-relative">
        <b-table
          v-if="change_view_card"
          :fields="$t(`${fields}`)"
          :items="infoTable"
          id="table-searcher"
          @row-clicked="rowTableClick"
          head-variant="light"
        >
          <template #head(id)>
            <b-form-checkbox class="checkboxx-table-searcher" v-model="selected_all"></b-form-checkbox>
          </template>
          <template #cell(id)="data">
            <div class="d-flex justify-content-between position-absolute">
              <b-form-checkbox
                class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                v-model="checked_influencer"
                :value="data.item.id"
                :ref="`checkbox_table_${data.item.id}`"
              >
              </b-form-checkbox>
              <h6 class="id-table-searcher" :ref="`id_table_${data.item.id}`">{{data.item.id}}</h6>
            </div>

          </template>
          <template
            #cell(username)="data"
          >
            <div>
              <b-media vertical-align="center">
                <template #aside>
                  <div style="position: relative">
                    <b-avatar
                      size="40px"
                      :src="data.item.username.avatar"
                      variant="light-primary"
                    />
                  </div>
                </template>
                <div class="d-flex flex-column justify-content-around media-container-avatar">
                  <span class="font-weight-bold d-block text-nowrap mb-0">
                    {{ data.item.username.name.username }}<image-verified v-if="data.item.username.name.is_verified"/>
                  </span>
                  <span v-if="data.item.actions.username" class="font-weight-bold d-block text-nowrap mb-0 text-muted-username">
                    {{ data.item.actions.fullname }}
                  </span>
                </div>                
              </b-media>
            </div>
          </template>
          <template #cell(followers)="data">
            <p
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ engagement(data.item.followers) }}
            </p>
          </template>
          <template #cell(audience)="data">
            <p
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ engagement(data.item.audience) }}
            </p>
          </template>
          <template #cell(tp)="data">
            <p
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ (decimalFixed(data.item.tp,2)) }} %
            </p>
          </template>
          <template #cell(tweets)="data">
            <p
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ numberFormat(data.item.tweets) }}
            </p>
          </template>
          <template #cell(retweets)="data">
            <p
              class="font-weight-bold d-block text-nowrap mb-0"
            >
              {{ numberFormat(data.item.retweets) }}
            </p>
          </template>
          <template #cell(category)="data">
            <p
              v-for="cat, index in data.item.category" :key="`${netSelected}-${index}`"
              class="d-block text-nowrap mb-0"
            >
              <b-badge pill variant="light-secondary-v2" class="light-secondary-v2" v-if="utils.checkNumber(cat)">{{getName(cat)}}</b-badge>
              <b-badge pill variant="light-secondary-v2" class="light-secondary-v2" v-else>{{cat}}</b-badge>
            </p>
          </template>
          <template #cell(quality)="data">
            <span :style="`background:${colorStatus(data.item)}; padding: 5px; border-radius: 50px; color:white`">
              {{ data.item.quality ? data.item.quality.title : 'N/A' }}
            </span>
          </template>
          <template
            #cell(actions)="data"
          >
            <b-row>
              <b-col>
                <b-row>
                  <a
                    :href="getURL(getLocalNetworkName(searchData.st), searchData.st === 'yt' ? data.item.actions.channel_id : data.item.actions.username, searchData.st === 'yt' ? true : false)"
                    target="_blank"
                  >
                    <b-button
                      variant="flat-secondary"
                      v-b-tooltip.hover.v-dark
                      :title="$t('seeNetwork')"
                    >
                      <feather-icon icon="ExternalLinkIcon"></feather-icon>
                    </b-button>
                  </a>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </b-table>
        <div v-else class="row container-view-searcher col-12 p-0">
          <div v-for="(influencer, index) in infoTable" :key="index" class="col-12 col-lg-6 col-xl-3 p-0">
            <b-card class="card-view-searcher m-1">
              <b-form-checkbox
                class="d-none float-right checkbox-card-view"
                v-model="checked_influencer"
                :value="index + 1"
                :ref="`checkbox_card_${index + 1}`"
              >
              </b-form-checkbox>
              <b-avatar class="card-image-influencer col-4 p-0" rounded="left" :src="influencer.username.avatar" />
              <b-card-text class="col-8 py-1">
                <div class="w-100">
                  <b-link class="username-card-view cursor-pointer" :href="getProfileUrl(influencer.actions)" target="_blank">
                    <h4>{{influencer.username.name.username}} <image-verified v-if="influencer.username.name.is_verified"/></h4>
                    <span class="text-muted-username" v-if="influencer.actions.username">
                      {{influencer.actions.fullname}}  
                    </span>
                  </b-link>
                  
                  <div class="mt-1 d-flex justify-content-between flex-wrap font-size-13">
                    <div>
                      <span class="follow-er-view-card">{{$t('campaigns.followers')}}</span>
                      <span class="d-block">{{getFormat(influencer.followers)}}</span>
                    </div>
                    <div>
                      <span class="follow-er-view-card">{{influencer.tp && netSelected !== 'twitch' ? 'ER' : $t('search.audience')}}</span>
                      <span class="d-block">{{influencer.tp && netSelected !== 'twitch' ? `${numberFormat(influencer.tp, 2)} %` : getFormat(influencer.audience)}}</span>
                    </div>
                  </div>
                </div>
            </b-card-text>
            </b-card>
          </div>
        </div>

        <div class="container-loader-searcher loader-table-cards" v-if="loader_results">
          <b-spinner class="spinner-table-searcher"></b-spinner>
        </div>
      </div>
    </b-col>
    <b-col
      cols="12"
      style="text-align: center"
    >
      <ul
        role="menubar"
        aria-disabled="false"
        aria-label="Pagination"
        class="pagination my-0 b-pagination pagination-sm justify-content-center"
      >
        <li role="presentation" :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" aria-hidden="true" @click="first()" >
          <span
            role="menuitem"
            type="button"
            aria-label="Go to first page"
            class="page-link"
            ><span class="text-dark">&lt;&lt;</span></span
          >
        </li>
        <li role="presentation" :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" aria-hidden="true" @click="prev()" >
          <span
            role="menuitem"
            type="button"
            aria-label="Go to previous page"
            class="page-link"
            ><span class="text-dark">&lt;</span></span
          >
        </li>
        <li role="presentation" class="page-item active">
          <button
            role="menuitemradio"
            type="button"
            aria-label="Go to page 1"
            aria-checked="true"
            aria-posinset="1"
            aria-setsize="43789"
            tabindex="0"
            class="page-link"
          >
            <b>{{currentPage}}</b>
          </button>
        </li>
        <li role="presentation" :class="`page-item ${!nextPage ? 'disabled' : ''}`" aria-hidden="true" @click="next()">
          <button
            role="menuitem"
            type="button"
            tabindex="-1"
            aria-label="Go to next page"
            class="page-link"
          >
            <span class="text-dark">&gt;</span>
          </button>
        </li>
        <li role="presentation" :class="`page-item ${!nextPage ? 'disabled' : ''}`" aria-hidden="true" @click="last()" >
          <button
            role="menuitem"
            type="button"
            tabindex="-1"
            aria-label="Go to last page"
            class="page-link"
          >
            <span class="text-dark">&gt;&gt;</span>
          </button>
        </li>
      </ul>
    </b-col>

    <sidebar-search 
      :open_sidebar="open_sidebar" 
      :key="update_sidebar" 
      :data="data_sidebar"
      :network="toogleFilter.st"
      class="open-sidebar-search"
      v-if="open_sidebar"
      @add_to_campaign="addInfuencerButton"
    >
    </sidebar-search>
  </b-row>
</template>

<script>
import {
  BImg,
  BTable, 
  BAvatar, 
  BBadge, 
  BRow, 
  BCol, 
  BFormInput, 
  BButton, 
  BButtonGroup,
  BTab, 
  BTabs, 
  VBTooltip, 
  BMedia,
  BLink, 
  BFormCheckbox,
  BCard, 
  BCardText, 
  BSpinner,
} from 'bootstrap-vue'
import filtroInstagram from '@/views/buscador/filtrosInstagram.vue'
import filtroTiktok from '@/views/buscador/filtroTIktok.vue'
import filtroYoutube from '@/views/buscador/filtroYoutube.vue'
import filtroTwitter from '@/views/buscador/filtroTwitter.vue'
import filtroTwitch from '@/views/buscador/filtroTwitch.vue'
import service from '@/services/search'
import utils from '@/libs/utils'
import { decimalFixed, numberFormat, getFormat} from '@/libs/utils/formats'
import { getURL } from '@/libs/utils/urls'
import { getLocalNetworkName } from '@/libs/utils/icons'
import { getMotivationalPhrase } from '@/libs/utils/others'
import service_getCamp from '@/services/dashboard'
import service_campaigns from '@/services/campaign'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations';
import service_profile from '@/services/others';
import lists_service from '@/services/lists';
import ImageVerified from '@/views/components/image/ImageVerified.vue';
import SidebarSearch from '@/views/buscador/SidebarSearch.vue';
import dropdownCampaigns from "@/views/campaings/dropdownCampaigns.vue";
import dropdownLists from '@/views/campaings/dropdownLists.vue';

export default {
  components: {
    BImg,
    BButtonGroup,
    SidebarSearch,
    BFormCheckbox,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTab,
    BTabs,
    BMedia,
    filtroInstagram,
    filtroTiktok,
    filtroYoutube,
    filtroTwitter,
    filtroTwitch,
    BLink,
    BCard,
    ImageVerified,
    BCardText,
    BSpinner,
    dropdownCampaigns,
    dropdownLists,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      networks: [
        {index: 0, network: 'ig'},
        {index: 1, network: 'yt'},
        {index: 2, network: 'tt'},
        {index: 4, network: 'tw'},
        {index: 3, network: 'tr'},
      ],
      decimalFixed, 
      numberFormat, 
      getFormat,
      getURL,
      getLocalNetworkName,
      data_sidebar: null,
      open_sidebar: false,
      update_sidebar: 0,
      checked_influencer: [],
      required,
      netSelected:'instagram',
      addInfluencer: {
        network:'',
        username:'',
        image:'',
        followers:0
      },
      options : [],
      updateTable: 0,
      searchString: '',
      toogleFilter: {
        show: false,
        st: 'ig'
      },
      searchData: {
        keywords: '',
        st: 'ig',
        page: 1
      },
      infoTable: [],
      total: 0,
      currentPage: 1,
      utils,
      local: window.location.origin,
      nextPage: true,
      hasPrev: 100,
      networkTabColor: 'instagram',
      active_tab: 0,
      selected_campaign: null,
      selected_all: null,
      change_view_card: true,
      loader_results: false,
      lists: [],
      interval_loading: null,
      initial_filters: {}
    }
  },
  created () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hash = urlParams.get('hash')

    if (hash) {
      this.searchHashQuery(hash)
    } else {
      this.firstPage(this.searchData)
    }

    this.getCampaigns()
    this.getLists();
  },
  computed: {
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    fields () {
      let table = ''
      switch (this.searchData.st) {
      case 'ig':
        table = 'tableSearch.ig'
        break
      case 'yt':
        table = 'tableSearch.yt'
        break
      case 'tt':
        table = 'tableSearch.tt'
        break
      case 'tr':
        table = 'tableSearch.tr'
        break
      case 'tw':
        table = 'tableSearch.tw'
        break
      }
      return table
    }
  },
  watch : {
    active_tab(val) {
      this.selected_all = null;
      this.open_sidebar = false;
      this.update_sidebar += 1;
      if (val === 0) {
        this.networkTabColor = 'instagram'
      } else if (val === 1) {
        this.networkTabColor = 'youtube'
      } else if (val === 2) {
        this.networkTabColor = 'tiktok'
      } else if (val === 3) {
        this.networkTabColor = 'twitter'
      } else if (val === 4) {
        this.networkTabColor = 'twitch'
      }
    },
    checked_influencer(val) {
      this.checkCheckboxClasses(val);
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_influencer = [];
    },
    change_view_card() {
      setTimeout(() => {
        this.checkCheckboxClasses(this.checked_influencer);
      }, 500)
    }
  },
  methods: {
    searchHashQuery(hash) {
      const jsonString = atob(hash);
      const search = JSON.parse(jsonString);
      const network = this.networks[search.st - 1]

      this.active_tab = network.index
      this.searchData.st = network.network
      this.toogleFilter.st = network.network
      this.initial_filters = search
    },
    getFormatInfluencerToAdd() {
      const influencers_to_add = [];
      this.checked_influencer.map((item) => {
        const influencer = this.infoTable[item - 1];
        influencers_to_add.push({
          influencer: influencer.actions.username,
          username: influencer.actions.username, 
          network: this.netSelected, 
          image: influencer.username.avatar,
          followers: influencer.followers, 
          channel_id: (this.netSelected === 'youtube') ? influencer.actions.channel_id : undefined,
          categories: influencer.category,
        });
      });
      return influencers_to_add;
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.infoTable.length; i++) {
          if (this.change_view_card) {
            this.$refs[`checkbox_table_${i}`].$el.classList.remove('d-none')
            this.$refs[`checkbox_table_${i}`].$el.classList.remove('checkbox-table-searcher')
            this.$refs[`id_table_${i}`].classList.add('d-none')
          } else {
            this.$refs[`checkbox_card_${i}`][0].$el.classList.remove('d-none')
          }
        }
      } else {
        for (let i = 1; i <= this.infoTable.length; i++) {
          if (this.change_view_card) {
            this.$refs[`checkbox_table_${i}`].$el.classList.add('d-none')
            this.$refs[`checkbox_table_${i}`].$el.classList.add('checkbox-table-searcher')
            this.$refs[`id_table_${i}`].classList.remove('d-none') 
          } else {
            this.$refs[`checkbox_card_${i}`][0].$el.classList.add('d-none')
          }
        }
      }
    },
    closedDropdown() {
      for (let i = 0; i < this.lists.length; i++) {
        this.$refs[`plus-icon-list-${i}`][0].classList.remove('d-none');
        this.$refs[`check-icon-list-${i}`][0].classList.add('d-none');
      }
      for (let i = 0; i < this.options.length; i++) {
        this.$refs[`plus-icon-campaign-${i}`][0].classList.remove('d-none');
        this.$refs[`check-icon-campaign-${i}`][0].classList.add('d-none');
      }
    },
    toastSelectInfluencer() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title:  this.$t('search.check_influencer_title'),
          icon: "AlertTriangleIcon",
          variant: "danger",
          text: this.$t('search.check_influencer_text')
        }
      });
    },
    addToList(list_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.remove('d-none');
      this.selected_all = null;
      const influencers_ar = [];
      this.checked_influencer.map((id) => {
        const influencer = this.formatDataInfluencer(this.infoTable.find(data => data.id === id));
        influencers_ar.push(influencer);
      });
      lists_service.bulkAddInfluencer(list_uuid, {influencers: influencers_ar}).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:  this.$t('lists.addinfluencer'),
            icon: "CoffeeIcon",
            variant: "success",
            text: this.$t('lists.addlist')
          }
        });
        setTimeout(() => {
          this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.add('d-none');
          this.$refs[`plus-icon-list-${index_spinner}`][0].classList.add('d-none');
          this.$refs[`check-icon-list-${index_spinner}`][0].classList.remove('d-none');
        }, 500);
      });
    },
    getLists() {
      lists_service.getLists().then((response) => {
        const lists = this.orderLists(response.results)
        lists.map((item) => {
          this.lists.push({value: item.uuid, text: item.title})
        });
      });
    },
    orderLists(lists) {
      lists.sort(function(a, b) {
        return a.order - b.order;
      });

      return lists
    },
    rowTableClick(data) {
      this.open_sidebar = true; 
      this.update_sidebar += 1;
      this.data_sidebar = data;
    },
    checkedAll() {
      this.checked_influencer = [];
      this.infoTable.forEach((item) => {
        this.checked_influencer.push(item.id)
      })
    },
    handleCheck(campaign_uuid = null, index_spinner = null) {
      this.selected_campaign = campaign_uuid;
      this.selected_all = null;
      if (this.checked_influencer.length === 0) {
        this.show_modal = false;
        this.change_modal = false;
        this.addInfluencerToCampaign()
      } else {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
        this.addCheckedInfluencer(index_spinner);
      }
    },
    formatDataInfluencer(data) {
      const object_influencer = {}
      object_influencer.influencer = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      object_influencer.username = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      object_influencer.network = this.netSelected;
      object_influencer.image = data.username.avatar;
      object_influencer.followers = data.audience;
      object_influencer.categories = data.category;

      return object_influencer
    },
    addCheckedInfluencer(index_spinner) {
      const influencers = []
      this.checked_influencer.map((id) => {
        const influencer = this.formatDataInfluencer(this.infoTable.find(data => data.id === id))
        influencers.push(influencer)
      })
      service_campaigns.addInfluencersCampaign(this.selected_campaign, influencers)
        .then(() => {
          this.selected_campaign = null;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('search.added'),
              icon: 'CheckCircleIcon',
              text: this.$t('search.hasBeenAdded'),
              variant: 'success'
            }
          })
          setTimeout(() => {
            this.$refs[`plus-icon-campaign-${index_spinner}`][0].classList.add('d-none')
            this.$refs[`check-icon-campaign-${index_spinner}`][0].classList.remove('d-none')
            this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
          }, 500);
          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            });
        })
    },
    getProfileUrl(actions) {
      const network = getLocalNetworkName(this.searchData.st)
      const username = this.searchData.st === 'yt' ? actions.channel_id : actions.username
      return `${this.local}/perfil/${network}/${username}`
    },
    addInfuencerButton(data, uuid = null) {
      this.addInfluencer.username = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      this.addInfluencer.network = this.netSelected;
      this.addInfluencer.image = data.username.avatar;
      this.addInfluencer.followers = data.followers;
      if (uuid === null) {
        this.change_modal = !this.change_modal;
        this.show_modal = true;
      } else {
        this.selected_campaign = uuid;
        this.addInfluencerToCampaign();
      }
    },
    addInfluencerToCampaign() {
      const form = new FormData()
      form.set('username', this.addInfluencer.username);
      form.set('network', this.addInfluencer.network);
      form.set('image', this.addInfluencer.image);
      form.set('followers', this.addInfluencer.followers);
      service_campaigns.addInfluncerv3(this.selected_campaign, form).then((e) => {
        if (e.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.errorInfluencer'),
              icon: 'BellIcon',
              text: e.data.response.message,
              variant: 'danger'
            }
          },
          {
            position: 'top-left'
          })
        } else if (e.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.errorInfluencer'),
              icon: 'BellIcon',
              text: e.data.response.message,
              variant: 'danger'
            }
          },
          {
            position: 'top-left'
          })
        } else {
          this.selected_campaign = null;
          this.$bvModal.hide('selectCampaign')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('search.added'),
              icon: 'CheckCircleIcon',
              text: this.$t('search.hasBeenAdded'),
              variant: 'success'
            }
          })

          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        }
      });
    },

    getCampaigns() {
      service_getCamp.getCampaign().then((res) => {
        res.response.results.filter((item) => {
          const obj = {value:item.uuid, text: item.name}
          this.options.push(obj)
        });
      });
    },

    getFilter (searching) {
      this.searchString = ''
      this.searchData.keywords = ''
      this.currentPage = 1
      this.searchData.page = this.currentPage

      this.toogleFilter.st = this.searchData.st
      this.checked_influencer = []

      if (searching) this.firstPage(this.searchData)
      else this.searchData = {
        keywords: '',
        st: this.toogleFilter.st,
        page: 1
      }
    },
    //pagination methods
    next () {
      if (this.nextPage) {
        this.currentPage += 1
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }

    },
    prev () {
      if (this.hasPrev > 100) {
        this.currentPage -= 1
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }
    },
    last () {
      if (this.nextPage) {
        this.currentPage = Math.round(this.total / 100)
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }

    },
    first () {
      if (this.hasPrev > 100) {
        this.currentPage = 1
        this.searchData.page = 1
        this.firstPage(this.searchData)
      }

    },
    firstPage(datos) {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      return new Promise((resolve,reject) => {
        service.getFirstPage(datos)
          .then(response => {
            this.infoTable = []
            this.total = response.data.total
            this.parseData(response.data.data)
            this.updateTable += 1
            this.nextPage = response.data.has_next
            this.hasPrev = response.data.next_offset
            clearInterval(this.interval_loading)
            this.$vs.loading.close()
            resolve(response)
          }).catch(e => {
            reject(e)
          })
      })
    },
    // finish pagination methods
    //onSearch
    getSearchResults (datos) {
      this.loader_results = true;
      service.saveLogSearch(datos)
        .then(() => {
        })

      return new Promise((resolve,reject) => {
        service.getResults(datos)
          .then(response => {
            this.infoTable = []
            this.total = response.data.total
            this.parseData(response.data.data)
            this.updateTable += 1
            this.nextPage = response.data.has_next
            this.hasPrev = response.data.next_offset
            this.loader_results = false;
            resolve(response)
          }).catch(e => {
            reject(e)
          })
      })
    },
    getSearch () {
      this.currentPage = 1
      this.searchData.page = 1
      this.searchData.keywords = this.searchString
      this.getSearchResults(this.searchData)
    },
    searchFilters(datos) {
      this.currentPage = 1
      this.searchData = datos
      this.getSearchResults(this.searchData)
    },
    getName(cat) {
      let entry = false
      switch (this.searchData.st) {
      case 'ig':
        entry = this.$t('instaCategories').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      case 'yt':
        entry = this.$t('youtubeCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;

      case 'tt':
        entry = this.$t('tiktokCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      }

      return ''
    },
    //utils
    parseData (data) {
      data.forEach(item => {
        const row = []
        row.id = item[0]
        row.username = {
          avatar: item[1].ava,
          name: item[1],
        }
        row.followers = item[2]
        row.audience = item[3]
        row.actions = item[1]
        row.audience = item[3]

        switch (this.searchData.st) {
        case 'ig':
          row.tp = item[4]
          row.quality = item[5]

          row.category = item[9]
          break;
        case 'yt':
          row.quality = item[6]
          row.actions = {
            username: item[1].username ? item[1].username : item[1].fullname,
            channel_id: item[1].social_id,
            fullname: item[1].username ? item[1].username : item[1].fullname,
          }

          row.category = item[9]
          break;
        case 'tt':
          row.tp = item[5]
          row.actions = item[1]
          row.quality = item[7]

          row.category = item[10]
          break;
        case 'tw':
          row.tp = item[5]
          row.category = item[7]
          break;
        case 'tr':
          row.tp = item[3]
          row.tweets = item[6]
          row.retweets = item[7]
          break;
        default:
          return 0
        }
        this.infoTable.push(row)
      })
    },
    engagement (num) {
      let result = ''
      if (num > 999) {
        result = `${(Math.round(num / 100) / 10)} K`
      }
      if (num > 999999) {
        result = `${(Math.round(num / 100000) / 10)} M`
      }
      if (num > 999999999) {
        result = `${(Math.round(num / 100000000) / 10)} B`
      }
      return result
    },
    separatebycomma (num) {
      const result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return result
    },
    colorStatus (item) {
      if (!item.quality) return '#F17171';
      if (item.quality.title === 'Muy bueno') return 'yellowgreen'
      if (item.quality.title === 'Promedio') return '#D5B900'
      if (item.quality.title === 'Excelente') return '#00AE65'
      if (item.quality.title === 'Buen') return 'darkorange'
      if (item.quality.title === 'Bajo') return '#EC4242'
      if (item.quality.title === 'Podria ser mejorado') return '#F17171'
      return 'yellowgreen'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style>
.network-x-search {
  width: 17px;
  margin-right: 4px;
  margin-bottom: 3px
}
.responsive-mode-views {
  justify-content: space-between;
}
.container-mode-views {
  display: flex;
  align-items: center;
}
.select-items {
  display: flex;
  justify-content: center;
  border-radius: 100px;
  width: 30px;
}
.row-main-searcher {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.size-icon{
  font-size: 1em;
  padding: 2px;
}
.ancho{
    width: 200px;
}
.tabs-searcher-networks .nav-tabs {
  margin: 0 !important;
}
.tabs-searcher-networks .nav-link {
  color: #718096;
  padding: 0.7em;
  font-weight: bolder;
  letter-spacing: -0.3px;
  font-size: 13px;
}
.tabs-searcher-networks .nav-link.active {
  background-color: white;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: black;
}
.color-searcher-tab-instagram > svg {
  color: #ed4960 !important;
}
.color-searcher-tab-youtube > svg {
  color: #ff051d !important;
}
.color-searcher-tab-tiktok > span {
  color: #ff0653 !important;
}
.color-searcher-tab-twitch > svg {
  color: #9449fa !important;
}
.color-searcher-tab-twitter > svg {
  color: #009cec !important;
}
.tiktok-searcher-icon{
  width: 20px;
  height: 20px;
  font-size: 16px;
  color: #718096;
}
.nav-tabs .nav-link:after {
  background: none !important;
}
.force-color {
  color: #0f131a;
}
.border-icon{
  font-size: 0.8em;
  border-radius: 50%; 
  background: white; 
  padding: 2px;
  position: absolute; 
  bottom: -5px; 
  right: -3px;
}
.filter-outline {
  border: 1px solid transparent;
  position: relative;
}
.color-gray-light {
  color: #718096;
}
.outline-gray-light .btn {
  color: #718096 !important;
  border: 1px solid #ccc;
}
.filter-outline:hover {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 250ms;
}
.border-right-light {
  border-right: 0.05em solid #E2E8F0;
}
.font-weight-results {
  text-align: left;
  font-weight: bolder;
  padding: 0.4em 0;
}
.h-100 {
  height: 100% !important;
}
.dropdown-filters {
  height: 100% !important;
}
.dropdown-filters .btn {
  height: 100% !important;
  padding: 12px 16px !important;
}
.button-gender {
  border: 1px solid #ccc;
  padding: 1em;
}
.button-gender:hover {
  background-color: rgb(231, 231, 231);
  cursor: pointer;
}
.border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.border-radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.white-space-wrap {
  white-space: pre-wrap;
}
.background-clicked-form {
  background-color: rgb(231, 231, 231);
}
.white-space-wrap {
  white-space: pre-wrap;
}
.background-clicked-form {
  background-color: rgb(231, 231, 231);
}
.chev-more-filters {
  color: #a0aec0;
  margin-left: 0.5em;
}
.toggle-collapse-filters:hover .chev-more-filters {
  color: #4b4b4b;
  transition: all 500ms;
}
.more-filters-button, .clear-filters-button {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin: 0.7em 0 1.7em;
  border-radius: 0.42rem;
  height: 41.5px;
}
.disabled-button-categories {
  color: rgb(218, 218, 218);
  font-weight: 800;
}
.more-filters-button > button {
  width: 100%;
  color: #009cec;
}
.more-filters-button > button:hover {
  color: #006394;
}
.clear-filters-button > button {
  width: 100%;
  color: #82868b;
}
.background-white {
  background-color: white !important;
  overflow-x: hidden !important;
}
.footer-sidebar-filters {
  overflow-y: hidden;
  padding-top: 2em;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 15px;
}
.filters_used_number {
  display: block;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  background-color: #009cec;
  color: white;
  border-radius: 100%;
  font-size: 14px;
}
.text-filter-under {
  color: #718096;
  font-size: 12px;
}
.thead-light th {
  background-color: white !important;
}
#table-searcher-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-searcher td {
  border-bottom: 1px solid #eff3f6;
}
#table-searcher tr {
  background-color: white;
}
#table-searcher tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
.checkbox-table-searcher {
  display: none !important;
}
.position-absolute-checkbox-searcher {
  position: absolute;
  top: -7px;
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
#table-searcher tr:hover .checkbox-table-searcher {
  display: block !important;
}
#table-searcher tr:hover .id-table-searcher {
  display: none;
}
#table-searcher th {
  padding: 15px 18px 15px 28px !important;
}
.text-muted-username {
  color: #718096;
  font-size: 13px;
}
.media-container-avatar {
  height: 50px;
}
.container-view-searcher {
  margin: 0 auto;
}
.card-view-searcher  .card-image-influencer {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
  align-items: center !important;
}
.follow-er-view-card {
  font-weight: 500;
}
.font-size-13 {
  font-size: 13px;
}
.username-card-view:hover > h4 {
  transition: all 250ms;
  color: #009cec;
}
.checkbox-card-view {
  position: absolute;
  right: 0;
  top: 8px;
}
.card-view-searcher > .card-body {
  padding: 0;
  display: flex;
}
.card-view-searcher:hover .checkbox-card-view {
  display: block !important;
}
.card-view-searcher:hover {
  transform: scale(1.05, 1.05);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.b-sidebar-backdrop {
  background-color: rgba(34, 41, 47);
  animation: sidebar-black-bakground 300ms;
}
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  top: 0px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 10em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.toggle-collapse-filters > span, .toggle-collapse-filters > strong > svg {
  margin-right: 0.4em;
}
.color-gray-button {
  color: #82868b;
  font-weight: 500;
  line-height: 5px;
}
.outline-secondary-hover {
  border-color: #82868b;
  color: #82868b;
}
.outline-secondary-hover:hover {
  color: #82868b;
  background-color: white;
}
.category-filters-searcher {
  width: 1300px !important;
  font-size: 13px;
  max-height: 500px;
  overflow: hidden;
}
.content-description-header {
  overflow: hidden;
}
.dropdown-filter-content-description > button {
  z-index: 0 !important;
  padding: 0 !important;
}
.input-search-by-influencer {
  border: none ;
  box-shadow: none;
  margin: 0 0 0 1em;
}
.input-search-by-influencer:focus {
  box-shadow: none !important;
}
.span-filter-content-description, .span-add-keyword {
  padding: 0.8em;
  width: 200px;
}
.span-add-keyword {
  color: #009cec;
  cursor: pointer;
  font-weight: bold;
}
.chevron-dropdown-search-by {
  position: absolute;
  right: -0.6em;
}
.chevron-dropdown-categories {
  position: absolute;
  right: 0px;
}
.category-item-dropdown {
  padding: 0.7em 0.2em;
  margin: 0 0.8em;
}
.category-item-dropdown > svg {
  left: -1.3em;
  width: 1.2em;
  height: 1.2em;
}
.category-item-dropdown:hover {
  background-color: #EFF3F6;
  cursor: pointer;
}
@media(max-width: 525px) { 
  .container-mode-views {
    align-items: center;
    display: block;
    margin-top: 12px;
  }
  .responsive-button {
    margin-block: 20px;
  }
  .responsive-mode-views {
    justify-content: center;
  }
}
@media(min-width: 1200px) { 
  .more-filters-button {
    order: unset !important;
  }
}
@keyframes sidebar-black-bakground {
  0% {
    opacity: 0;
  }
  100% {
    background-color: rgba(34, 41, 47, 0.75);  
  }
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
@media(max-width: 575px){
    .tabs-center ul li{
      width: 100% !important;
    }
  }
@media(min-width: 576px) and (max-width: 768px){
  .tabs-center ul li{
    width: 50% !important;
  }
}
</style>
